<template>
<div class="deposit-container">
    <p class="title">
        واریز تومانی
    </p>
    <div class="info-container">
        <div class="coin-info">
            <div>
                <p class="label">
                    انتخاب شماره کارت
                </p>
                <div class="selected-container" @click="drop = !drop">
                    <!-- <img src="/coins/BITCOIN.png" alt=""> -->
                    <span v-if="!address"> انتخاب کارت </span>
                    <span> {{address}} </span>
                    <img class="dropIcon" src="@/assets/dropIcon.svg" alt="">
                    <div class="drop-down" v-if="drop">
                        <template v-for="(card,index) in state.userInfo.bankAccounts">
                        <div @click="address = card.cardNumber" :key="index">
                            <span> {{card.cardNumber}} </span>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
            <div>
                <p class="label">
                    مقدار واریزی
                </p>
                <input @input="amount = $toLocal(amount)" class="address-input" type="text" v-model="amount">
            </div>
            <button :disabled="!address || !amount" class="normal-btn">
               انتقال به درگاه پرداخت
            </button>
        </div>
        <div class="guide-container">
            <div>
                راهنمای واریز
            </div>
            <div class="warning-container">
                <img src="@/assets/warning.svg" alt="">
                <span>
                    تنها از کارت های عضو شتاب که در پروفایل شما تایید شده اند می توانید واریز انجام دهید
                </span>
            </div>
            <div @click="$router.push({name:'Account'})" class="limit-container">
                <span>
                    <img src="@/assets/plus.svg" alt="">
                    <span>افزودن شماره شبا</span>
                </span>
            </div>
        </div>
    </div>
    <div class="history-container">
        <p>
            تاریخچه واریز تومانی
        </p>
        <div  class="table-contaner">
            <span v-if="tableContents.length < 1" class="no-content">
                محتوایی جهت نمایش وجود ندارد
            </span>
            <perfect-scrollbar v-else @ps-y-reach-end="getDeposite">
            <table>
                <tr class="tr-head">
                    <td class="address">
                        زمان
                    </td>
                    <td>
                        مقدار درخواستی
                    </td>
                    <td class="address">
                        شماره کارت
                    </td>
                    <td>
                        وضعیت
                    </td>
                    <td class="address">
                        شناسه پیگیزی
                    </td>
                </tr>
                <tr class="tr-body" v-for="item , index in 7" :key="index">
                    <td class="address">
                        ۰۰/۲/۲ ۱۲:۲۳
                    </td>
                    <td>
                        500000
                    </td>
                    <td class="address">
                        101900000087987987
                    </td>
                    <td class="success">
                        موفق
                    </td>
                    <td class="address">
                        87987987
                    </td>
                </tr>
            </table>
            </perfect-scrollbar>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'RialWithdrawal',
    data() {
        return {
            address:'',
            amount:0,
            drop: false,
            tableContents:[],
            page:0,
            lastPage:1,
        }
    },
    methods: {
    async getDeposite(){
                if (this.page < this.lastPage) {
                    this.page++ ;
                    const res = await this.$axios.get('/wallets/deposit', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        console.log(res.content);
                        this.lastPage = res.totalPages
                        let content = res.content.filter(a => a.relatedCoin == 'TOMAN')
                        this.tableContents = [...this.tableContents, ...content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
                }
            },
    async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZarrinPal',
                cardNumber: this.topcardnumber,
                callBackURL: this.$callBackURL
            }

            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

            if (res.message === "Deposit Request Created Successfully") {
                this.closeModale();
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },
    },
    mounted() {
        this.getDeposite();
    },
    created:function (){
        window.addEventListener("click",event => {
            if(!event.target.closest('.selected-container')){
                this.drop=false
            }
        })
    }

}
</script>

<style lang="scss" scoped>
.deposit-container{
    min-height: 100vh
}
@import '../../../Styles/Wallet.scss' ;
</style>
